<template>
  <div>
    <el-row> 
      <el-col :span="24" class="infoTit">
        <span  v-if="goodsinfo.productVO.otcType===1 && goodsinfo.productVO.drugCategory==0"  class="tag bgRed titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.productVO.otcType===1 && goodsinfo.productVO.drugCategory==1"  class="tag bgGreen titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.productVO.otcType===0"  class="tag bgRed titColor radiusBox">Rx</span>
        <span  v-if="goodsinfo.productVO.newStatus==true||goodsinfo.productVO.newStatus=='true'"  class="tag bgGreen titColor">新品</span>
        <span  v-if="goodsinfo.productVO.medicalInsuranceType>0"  class="tag bgBlue titColor">医保</span>
        <span  v-if="goodsinfo.productVO.proprietary==1"   class="tag bgOrange titColor">自营</span>
       <span class="brandName">{{goodsinfo.productVO.brandName}} </span>{{ goodsinfo.productVO.name }}  
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>{{ goodsinfo.productVO.subTitle }}</span>
        <!--   <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
    </el-row>
    <el-row class="infoBox">
      <el-col :span="6">
        <span class="infoKey">会员价：</span
        ><span class="price"><strong>￥</strong>{{ goodsinfo.productVO.price==="***"?"会员可见":goodsinfo.productVO.price }}</span></el-col
      >
      <el-col :span="6">
        <span class="infoKey">推荐零售价：</span>￥{{
          goodsinfo.productVO.price==="***"?"会员可见":goodsinfo.productVO.originalPrice
        }}</el-col
      >
      <el-col :span="6"><span class="infoKey">毛利率：</span>{{ goodsinfo.productVO.grossProfitMargin }}%</el-col>
      <el-col :span="6"><span class="infoKey">净赚：</span>￥{{ goodsinfo.productVO.price==="***"?"会员可见":goodsinfo.productVO.earnings }}</el-col>
      <el-col :span="24" class="infoActivity"  v-for="(item,index) in promotionMap" :key="index">
        <span class="infoKey" v-if="index==0">促销活动:</span>
        <span class="infoKey" v-else></span>
        <span class="infoTag">{{item.type}}</span>
        <span class="infoDes">{{item.name}}</span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row  :span="24"  class="infoParameter">
      <div><span class="infoKey">商品编号:</span><span>{{goodsinfo.productVO.productSn }}</span></div>
       <div><span class="infoKey">国药准字:</span><span>{{goodsinfo.productVO.approvalNo}}</span></div>
      <div><span class="infoKey">药品规格:</span><span>{{goodsinfo.productVO.specification}}</span></div>
      <div><span class="infoKey">件装规格:</span><span>{{goodsinfo.productVO.partSpecification}}</span></div>
      <div><span class="infoKey">生产日期:</span><span>{{goodsinfo.productVO.noProducedDateFromat}}</span></div>
      <div><span class="infoKey">效期优于:</span><span>{{goodsinfo.productVO.validDateFromat}}</span></div>
      <div><span class="infoKey">医保类型:</span><span>{{goodsinfo.productVO.medicalInsuranceType===0?'非医保':goodsinfo.productVO.medicalInsuranceType===1?'甲类医保':'乙类医保'}}</span></div>
      <div>
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
        <!-- <span>{{goodsinfo.quantity==-1 ||goodsinfo.quantity==0 ?'备货中':goodsinfo.quantity>1000?"充足":goodsinfo.quantity+goodsinfo.unit}}</span> -->
          <span v-if="goodsinfo.productVO.noExpirationStock==-1 || goodsinfo.productVO.noExpirationStock< Number(goodsinfo.productVO.minOrderNum)">备货中</span>
          <span v-if="goodsinfo.productVO.noExpirationStock>1000">充足</span>
          <span v-if="goodsinfo.productVO.noExpirationStock<=1000 && goodsinfo.productVO.noExpirationStock>=Number(goodsinfo.productVO.minOrderNum)">{{goodsinfo.productVO.noExpirationStock}}{{goodsinfo.productVO.unit}}</span>
      </div>
      <div v-if="goodsinfo.productVO.noExpirationStock<1 && goodsinfo.productVO.deliveryTimeFormat"><span class="infoKey">到货日期:</span><span>{{goodsinfo.productVO.deliveryTimeFormat}}</span></div>
      <div  @click="Download(goodsinfo.productVO.id)"> <span class="infoKey" >首营资料：</span> <strong class="downBtn">查看/下载</strong></div>
    </el-row>

    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.productVO.minOrderNum"
          :max="Number(goodsinfo.productVO.noExpirationStock)"
          :step="goodsinfo.productVO.minOrderNum"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns">
      <div :span="24">
        <span class="infoKey"></span>
        <!-- {{goodsinfo.productVO.noExpirationStock}} -->
        <button :disabled="goodsinfo.productVO.noExpirationStock<Number(goodsinfo.productVO.minOrderNum)" :class="goodsinfo.productVO.noExpirationStock< Number(goodsinfo.productVO.minOrderNum)?'grey shopBtn':'shopBtn'"  @click="immediateBuy(goodsinfo.productVO)">
          单独购买
          <span><strong>￥</strong>{{goodsinfo.productVO.price}}</span>
        </button>
        <button :disabled="goodsinfo.abPromotionProductVO.realQuantity<Number(goodsinfo.abPromotionProductVO.minOrderNum)" :class="goodsinfo.abPromotionProductVO.realQuantity< Number(goodsinfo.abPromotionProductVO.minOrderNum)?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">
          组合购买
           <span><strong>￥</strong>{{goodsinfo.abPromotionProductVO.price}}</span>
        </button>
      </div>
    </div>
<!-- 组合购买 -->
     <el-dialog title="组合购买" :visible.sync="dialogVisible" width="55%"  :before-close="handleClose">
        <div class="desBox">
          <p><span>必选商品说明：</span>必选商品是活动中系统指定的特价商品，参与活动并满足活动要求，必选商品价格为特价，不满足要求的情况下为原价。</p>
          <p><span>可选商品说明：</span>可选商品是活动中系统随机分配的商品，该商品价格为原价，购买可选商品达到活动条件时，必选商品价格为特价。</p>
        </div>
        <div class="ABBox">
           <div class="ABox">
              <div class="Atitle">必选商品</div>
              <div class="AproductBox" v-if="productAList!=''" >
                  <ABComItem class="item"  v-for="(item,index) in productAList" :key="index"  :info="item" :proType="A" ></ABComItem>
                  <div class="clear"></div>
              </div>
            </div>
            <div class="lineBox"></div>
            <div class="BBox">
              <div class="Atitle">可选商品<span>（选满{{goodsinfo.minPrice}}元，当前<strong>{{totalPrice}}</strong>元）</span></div>
              <div class="BproductBox" v-if="productBList!=''" >
                <ABComItem v-for="(item,index) in productBList" :key="index"  :info="item" :index="index"  :proType="B"  @changeNums="changePriceTotal"></ABComItem>
                  <div class="clear"></div>
              </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button :disabled="totalPrice < Number( goodsinfo.minPrice)"  :class="totalPrice< Number(goodsinfo.minPrice)?'grey addBtn':'addBtn'"  @click="saveFun()">立即购买</el-button>
        </div>
    </el-dialog>



  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
 ::-webkit-scrollbar  
{  
    width: 5px;  
}

/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(126, 126, 126, 0.1); 
} 

/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(126, 126, 126, 0.1);  
    background-color: rgba(126, 126, 126, 0.1);
} 
/deep/.el-dialog{
  background:#FAFAFA
}
/deep/.el-dialog__header {
  background: #ececec;
  padding: 5px 10px;
  .el-dialog__title {
    font-size: 14px;
    font-weight: bold;
  }
  .el-dialog__headerbtn {
    top: 8px;
  }
}
.clear{
  clear: both;
}
/deep/.el-dialog__body {
  padding: 12px 20px 0 20px;
  .ABBox{
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    max-height:550px;
  }
  .desBox{
    padding-left: 10px;
    background: #fff5be;
    border: 1px solid #F5D521;
    p{
      color: #8B780D;
      font-size: 14px;
      line-height: 30px;
      span{
        color: #4D4203;
        font-weight: bold;
      }
    }
  }
  .AproductBox{
    height: auto;
    margin-bottom:10px;
    .item:nth-child(4n + 0){
      margin-right: 0px;
    }
  }
  .BproductBox{
    height: auto;
    margin-bottom:20px;
    .item:nth-child(4n + 0){
      margin-right: 0px;
    }
  }
  .Atitle{
    text-align: center;
    color:#333333;
    font-size: 20px;
    font-weight: bold;
    margin-top:15px;
    margin-bottom: 12px;
    span{
      color: #333333;
      font-weight: normal;
      font-size: 16px;
      strong{
        color: #FFD303;
      }
    }
  }
  .lineBox{
    background: #EFEFEF;
    height: 1px;
  }
}
/deep/.el-dialog__footer{
      height: 60px;
}
/deep/.dialog-footer {
  text-align: left;
      height: 60px;
          float: right;
  .el-button {
    &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
       &.grey{
         background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
      }
  }
}
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName{
      font-weight: bolder;
      color: #333;
    }
    .tag{
        width:42px;
        text-align: center;
          height: 20px;
          // padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:50%;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .infoMsg {
    color: #FFD303;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
        margin-top: 5px;
  }
  span.infoKey {
    width: 86px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  .downBtn{
    cursor: pointer;
    &:hover{
         color: #FFD303;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 0;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
      &.shopBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey{
         background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
      }
    }
  }
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    div{
      float: left;
      width: 50%;
       margin-bottom: 15px;
        &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;
     
    // }
  }
  .infoBox {
    background: #f6f6f6;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top:10px;
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom:25px;
      }
    }

    span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.infoTag {
      border: 1px solid #FFD303;
      color: #FFD303;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
    }
    span.infoDes {
      color: #666;
    }
  }
}
</style>
<script>
const ABComItem = () => import("components/product/ABComItem.vue");
import {AddCart,DownloadFirstTest} from "api/product.js"
import { mapState,mapMutations,mapGetters} from "vuex";
export default {
  name: "GoodsInfo",
  data() {
    return {
      A:'A',
      B:'B',
      num:0,
      promotionMap:[],
      dialogVisible:false,
      productAList:[],
      productBList:[],
      TotalArr:[],
      totalPrice:0,
      OneNum:0
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {
    ABComItem
  },
  computed: {
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    })
  },
  methods: {
    saveFun(){
      let datailsArr=[];
      this.TotalArr.forEach(item => {
        //  console.log(Number(item.minOrderNum)*Number(item.price))
        if(item.minOrderNum>0){
            datailsArr.push({
              goodsCount:item.minOrderNum,
              goodsId:item.id,
              isRecentExpiration:0
            })
        }
         
      });
      let datas={ 
        details: datailsArr,
        orderType:4,
        promotionId:this.goodsinfo.id
      }
      sessionStorage.setItem('comBo',JSON.stringify(datas))
      // 单独成单，跳订单结算,query:{ids:moreIds}}
      this.$router.push({ name: "GoodsAmount",query:{id:"combo"}});
    },
    // 获取弹窗总价
    changePriceTotal(params){
      this.TotalArr[params.index].minOrderNum=params.num;
      this.PriceTotalFun()
    },
    PriceTotalFun(){

      // console.log('fghjk',this.TotalArr)
         this.totalPrice=0;
         this.TotalArr.forEach(item => {
            this.totalPrice+=Number(Number(item.minOrderNum)*Number(item.price))
          });
          this.totalPrice=this.totalPrice.toFixed(2)
    },
    // 关闭弹窗事件
     handleClose() {
         this.dialogVisible = false;
           this.productBList=this.goodsinfo.productBList
          //  console.log(this.goodsinfo.productBList)
      },
     Download(goodsId){
        // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
      //  alert(goodsId)
         DownloadFirstTest(goodsId).then(res=>{
           if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
                // 关闭loading
                this.$loading().close();
           }else{
            let href=res.data.data;
              // 关闭loading
            this.$loading().close();
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
        })
    },
     ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
    handleChange() {
      // value
    },
   addCartFun(data) {
       if(this.goodsinfo.productVO.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // 点组合购出弹窗
      this.dialogVisible=true
      this.OneNum+=1
      if(this.OneNum==1){
        this.productAList=data.productAList
        this.productBList=data.productBList
        this.productBList.forEach(item => {
          this.TotalArr.push({
            minOrderNum:item.minOrderNum,
            price:item.price,
            id:item.id
          })
        })
        this.PriceTotalFun()
      }
      
      // console.log(data)
    },
    immediateBuy:function(data) {

       if(this.goodsinfo.productVO.price==="***"){
          this.$message({
              message:'未认证用户不能购买',
              type:"warning"
          })
          return false
      }
      const addcartDate=[];
      addcartDate.push({
        // isRecentExpiration:data.isRecentExpiration,
        isRecentExpiration:0,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });
            let count=this.num
            this.setCarGoodsNum(count);
            this.$router.push({ name: "Cart" });

        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    }
  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
    // console.log(this.goodsinfo)
    this.num=this.goodsinfo.productVO.minOrderNum
    this.promotionMap=this.goodsinfo.productVO.promotionMap
 
  },
};
</script>
